<nz-layout class="app-layout">
  <nz-sider
    class="menu-sidebar"
    nzCollapsible
    nzWidth="256px"
    nzBreakpoint="md"
    [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null"
  >
    <div class="sidebar-logo">
      <a href="https://ng.ant.design/" target="_blank">
        <img src="assets/agile_logo.svg" alt="logo" />
        <h1>Agile maps</h1>
      </a>
    </div>
    <ul
      nz-menu
      nzTheme="dark"
      nzMode="inline"
      [nzInlineCollapsed]="isCollapsed"
    >
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/landing">Landing</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/maps">Maps</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/help">Help</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/about">About</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="siderToggle()">
          <i
            class="trigger"
            nz-icon
            [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
          ></i>
        </span>

        <div *ngIf="router.url.includes('maps')" class="center-col">
          <app-search style="margin-right: 10px"></app-search>

          <i
            style="margin-right: 10px"
            (click)="toggleMarkers()"
            nz-icon
            nzType="environment"
            nzTheme="outline"
            nz-tooltip
            nzTooltipTitle="Toggle Markers"
            class="map-toggler"
            [class.toggleOn]="markersStateOn.value"
          ></i>

          <i
            (click)="toggleRails()"
            nz-icon
            nzType="line-chart"
            nzTheme="outline"
            nz-tooltip
            nzTooltipTitle="Toggle Rails"
            class="map-toggler"
            [class.toggleOn]="railsStateOn.value"
          ></i>
          <i
            (click)="toggleClusters()"
            nz-icon
            nzType="cluster"
            nzTheme="outline"
            nz-tooltip
            nzTooltipTitle="Toggle Clusters"
            class="map-toggler"
            [class.toggleOn]="clustersStateOn.value"
          ></i>
        </div>

        <div></div>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <div class="map-content">
          <div class="map-content-left">
            <router-outlet></router-outlet>
          </div>
          <div class="sidebar-content-right">
            <app-sidebar *ngIf="router.url.includes('maps')"></app-sidebar>
          </div>
        </div>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
