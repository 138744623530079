<div class="search-wrapper">
  <div class="search">
    <nz-input-group (click)="searchArea.focus()" [nzSuffix]="suffixIconSearch">
      <input
        #searchArea
        type="text"
        (keyup)="searchStations(searchQuery)"
        [(ngModel)]="searchQuery"
        nz-input
        placeholder="search stations"
      />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i nz-icon nzType="search"></i>
    </ng-template>
  </div>
</div>
