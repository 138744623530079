import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';
import * as stations from '../stations.json';
import { BehaviorSubject } from 'rxjs';
import { EventService } from './event.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class MapService {
  map: mapboxgl.Map;
  lat = 50.1109;
  lng = 8.6821;
  primaryColor = '#e40e51';
  secondaryColor = 'rgb(129, 19, 58)';
  markers: Array<mapboxgl.Marker> = [];
  markersObject: mapboxgl.Marker = {};
  markersOnScreen: mapboxgl.Marker = {};
  stations: any = stations['default'];
  searchedStations: Array<object> = [];
  searchQuery: string = "";
  railsLayerId = 'rails-layer';
  clustersLayerId = 'cluster-layer';
  selectedLocationPopup: mapboxgl.Popup
  selectedPin: any


  public railsStateOn: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public markersStateOn: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public clustersStateOn: BehaviorSubject<boolean> = new BehaviorSubject(true);


  mapConfig = {
    container: 'map',
    style: 'mapbox://styles/mapbox/dark-v10',
    center: [this.lng, this.lat],
    zoom: 12,
    dragPan: true,
    scrollZoom: true
  };

  constructor(
    private readonly eventService: EventService,
    private readonly http: HttpClient
  ) {
    mapboxgl.accessToken = environment.mapbox.accessToken;
  }


  buildMap() {
    let p3 = performance.now()
    this.map = new mapboxgl.Map(this.mapConfig);
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.on("load", () => {
      this.setMapRailsSource()
      this.addMapRails()
      this.setMarkerImage()

      this.setClusterSource()
      this.addClusters()

      this.attachMapListeners()
    });
    let p4 = performance.now()
  }


  private setMarkerImage(): void {
    this.map.loadImage(
      'https://static.agiledatainnovation.de/opendata/agile-marker.png',
      (error, image) => {
        if (error) throw error;
        this.map.addImage('custom-marker', image);
      })
  }

  public getMarkers() {
    return this.markers;
  }

  public getMapInstance() {
    return this.map;
  }

  public getSelectedPin() {
    return this.selectedPin
  }


  public getStations(): Array<object> {
    return this.stations.features
  }

  public setMapRailsSource() {
    this.map.addSource(this.railsLayerId, {
      type: "geojson",
      data: environment.mapbox.rails_geojson,
    });
  }

  public setClusterSource() {
    this.map.addSource(this.clustersLayerId, {
      type: "geojson",
      data: environment.mapbox.stations_geojson,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    })
  }

  public addClusters() {
    this.addClustersLayer()
    this.addMarkersLayer()
  }


  private addClustersLayer() {
    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: this.clustersLayerId,
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'point_count'],
          this.primaryColor,
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    });

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: this.clustersLayerId,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    });
    
    this.clustersStateOn.next(true)
  }

  private addMarkersLayer() {
    this.map.addLayer({
      id: 'markers',
      type: 'symbol',
      source: this.clustersLayerId,
      filter: ['!', ['has', 'point_count']],
      layout: {
        'icon-image': 'custom-marker',
        'text-field': ['get', 'title'],
        'text-font': [
          'Open Sans Semibold',
          'Arial Unicode MS Bold'
        ],
        'text-offset': [0, 1.25],
        'text-anchor': 'top'
      },
    });

    this.markersStateOn.next(true)
  }

  public addPopup(card: any, coordinates: any) {
    if (this.selectedLocationPopup) {
      this.selectedLocationPopup.remove()
    }
    this.selectedLocationPopup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(`Bezeichnung: <strong>${card.BEZEICHNUNG}</strong> <br>Strecke NR:  <strong>${card.STRECKE_NR}</strong>`)
      .addTo(this.map)
  }

  private attachMapListeners() {
    // When clicking on smallest unit (station) display station info
    this.map.on('click', 'markers', (e) => {
      console.log(e)


      let locationProperties = e.features[0].properties
      let coordinates = e.features[0].geometry.coordinates.slice();

      this.eventService.emitSelectedPin(locationProperties)

      this.selectedLocationPopup = new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`Bezeichnung: <strong>${locationProperties.BEZEICHNUNG}</strong> <br>Strecke NR:  <strong>${locationProperties.STRECKE_NR}</strong>`)
        .addTo(this.map)
    })

    // When clicking on one big cluster, inspect cluster by zooming in
    this.map.on('click', 'clusters', (e: { point: any; }) => {
      console.log(e)
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      let clusterId = features[0].properties.cluster_id;
      this.map.getSource(this.clustersLayerId).getClusterExpansionZoom(
        clusterId,
        (err: any, zoom: any) => {
          if (err) return;

          this.map.flyTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
            essential: true,
          });
        }
      );
    })

    // Show cursor on hover over cluster
    this.map.on('mouseenter', 'clusters', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'clusters', () => {
      this.map.getCanvas().style.cursor = '';
    });
    this.map.on('mouseenter', 'markers', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'markers', () => {
      this.map.getCanvas().style.cursor = '';
    });
  }


  public addMapRails() {
    this.map.addLayer({
      id: this.railsLayerId,
      type: "line",
      source: this.railsLayerId,
      layout: {
        "line-join": 'round',
        "line-cap": 'round'
      },
      paint: {
        "line-color": this.primaryColor,
        "line-width": 2
      }
    });

    this.railsStateOn.next(true);
  }

  private removeLayer(layerToRemove: string) {
    if (this.map.getLayer(layerToRemove)) this.map.removeLayer(layerToRemove);
    if (layerToRemove === 'rails-layer') this.railsStateOn.next(false)
    if (layerToRemove === 'markers') this.markersStateOn.next(false)
    if (layerToRemove === 'clusters') this.clustersStateOn.next(false)
  }

  public toggleLayer(layer: string) {
    if (layer === 'rails') {
      if (this.railsStateOn.value) {
        this.removeLayer(this.railsLayerId);
      } else {
        this.addMapRails();
      }
    }

    if (layer === 'markers') {
      if (this.markersStateOn.value) {
        this.removeLayer('markers')
      } else {
        this.addMarkersLayer()
      }
    }

    if (layer === 'clusters') {
      if (this.clustersStateOn.value) {
        this.removeLayer('clusters')
        this.removeLayer('cluster-count')
      } else {
        this.addClustersLayer()
      }
    }
  }

  public searchStation(searchQuery: string): Array<object> {
    if (!searchQuery) {
      return this.stations
    }
    let searchedArray: Array<object> = [];
    searchedArray = this.stations.features.filter(query => query.properties.BEZEICHNUNG.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()))
    this.searchedStations = searchedArray;
    this.searchQuery = searchQuery
    return searchedArray
  }

  public fetchStations(): Observable<any> {
    return this.http.get(environment.mapbox.stations_geojson)
  }
}