import { Component } from '@angular/core';
import { EventService } from './services/event.service';
import { Router } from '@angular/router';
import { MapService } from './services/map.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isCollapsed = false
  public railsStateOn = this.mapService.railsStateOn;
  public markersStateOn = this.mapService.markersStateOn;
  public clustersStateOn = this.mapService.clustersStateOn;


  constructor(
    private eventService: EventService,
    private mapService: MapService,
    public router: Router
  ) {

  }

  public siderToggle() {
    this.isCollapsed = !this.isCollapsed

    setTimeout(() => {
      this.eventService.dispatchEvent('resize');
    }, 500);
  }

  public toggleRails() {
    this.mapService.toggleLayer('rails');
  }

  public toggleMarkers() {
    this.mapService.toggleLayer('markers');
  }

  public toggleClusters() {
    this.mapService.toggleLayer('clusters');
  }
}
