import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapComponent } from './components/map/map.component';

import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SearchComponent } from './components/search/search.component';
import { FormsModule } from '@angular/forms';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe'

@NgModule({
  imports: [
    CommonModule,
    NzLayoutModule,
    NzMenuModule,
    NzDrawerModule,
    NzCardModule,
    NzIconModule,
    NzInputModule,
    NzEmptyModule,
    NzToolTipModule,
    FormsModule,
  ],
  declarations: [
    MapComponent,
    SidebarComponent,
    SearchComponent,
    SearchHighlightPipe
  ],
  exports: [
    SearchHighlightPipe,
    MapComponent,
    NzLayoutModule,
    NzMenuModule,
    NzDrawerModule,
    NzCardModule,
    NzIconModule,
    NzInputModule,
    NzEmptyModule,
    NzToolTipModule,
    SidebarComponent,
    SearchComponent,
    CommonModule
  ]
})
export class SharedModule {}
