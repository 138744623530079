import { Injectable } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  @Output() onSelectedPin = new EventEmitter();
  @Output() onSelectedCard = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  constructor() { }

  public emitSelectedPin(event: any) {
    this.onSelectedPin.emit(event)
  }

  public emitSelectedCard(event: any) {
    this.onSelectedCard.emit(event)
  }

  public search(event: any) {
    this.onSearch.emit(event)
  }

  public dispatchEvent(eventName: string) {
    let event;
    if(typeof(Event) === 'function') {
        event = new Event(eventName);
    }else{
        event = document.createEvent('Event');
        event.initEvent(eventName, true, true);
    }
    window.dispatchEvent(event);
  }
}
