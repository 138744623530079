import { Component, OnInit, HostListener, HostBinding, ElementRef, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MapService } from '../../services/map.service';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  @ViewChild('sidebar') sidebarElem: ElementRef
  pinsData: any[] = [];
  currentCard: string = "";

  @HostBinding('style.width.px') sidebarWidth: number | null = null;
  @HostBinding('style.max-width.px') sidebarMaxWidth: number | null = null;
  @HostBinding('class.resizing') resizing: boolean = false;

  constructor(
    private mapService: MapService,
    private eventService: EventService,
  ) {
    this.eventService.onSelectedPin.subscribe((marker: any) => {
      this.currentCard = marker.GEOGR_BREITE + marker.GEOGR_LAENGE;

      let cardId = 'card' + this.currentCard;
      let card = document.getElementById(cardId);
      let cardOffsetTop = card.offsetTop;
      card.parentElement.scrollTop = cardOffsetTop - 100;

      let currentCard = this.pinsData.find((x: any) => {
        let pinLatLng = '' + x.GEOGR_BREITE + x.GEOGR_LAENGE;

        return pinLatLng === this.currentCard;
      });

      this.expandCardDetails(currentCard);
    })

    this.eventService.onSearch.subscribe((searchQuery: string) => {
      this.searchPins(searchQuery)
    })
  }

  ngOnInit(): void {
    if (this.mapService.searchedStations.length > 0) {
      this.pinsData = this.mapService.searchedStations
    } else {
      this.pinsData = this.mapService.getStations()
    }
  }


  identify(index, item) {
    return item.STRECKE_NR;
 }


  selectCardPin(selectedCard: any) {
    this.currentCard = selectedCard.GEOGR_BREITE + selectedCard.GEOGR_LAENGE;
    this.eventService.emitSelectedCard(selectedCard);
    if (selectedCard.detailsExpanded === true) {
      this.expandCardDetails(selectedCard);
    }
  }

  public searchPins(searchQuery: string) {
    this.sidebarWidth = this.sidebarElem.nativeElement.offsetWidth
    this.pinsData = this.mapService.searchStation(searchQuery)
  }

  public expandCardDetails(card: any) {
    for (let pinData of this.pinsData) {
      pinData['detailsExpanded'] = false;
    }
    card.detailsExpanded = true;
  }

  public parsePinDetails(pinData: object) {
    return JSON.stringify(pinData, null, 1)
      .replace(/,/gm, '')
      .replace(/{/gm, '')
      .replace(/}/gm, '')
      .replace(/"/gm, '')
      .replace(/detailsExpanded: true/gm, '')
      .trim();
  }

  public toFixed(num: number, fixed: number) {
    let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  public resizeStart() {
    this.resizing = true;

    let body = document.getElementsByTagName("body")[0];
    body.style.cssText = "user-select: none";
  }

  public resizeEnd() {
    this.eventService.dispatchEvent('resize');
    this.resizing = false;

    let body = document.getElementsByTagName("body")[0];
    body.style.cssText = "";
  }

  @HostListener("document:mousemove", ["$event"])
  onMouseMove(e: any) {
    if (this.resizing) {
      let mouseRight = window.innerWidth - e.pageX;
      this.sidebarWidth = mouseRight - 24;
    }
  }

  @HostListener("document:mouseup", ["$event"])
  onMouseUp(e: any) {
    if (this.resizing) {
      this.resizeEnd();
    }
  }

  isOverflown(element: any) {
    return element.scrollWidth > element.clientWidth;
  }
}
