import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { EventService } from '../../services/event.service'
import { MapService } from '../../services/map.service'

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit {
  @ViewChild('searchArea') searchArea: ElementRef
  searchQuery: string = ""

  constructor(
    private eventService: EventService,
    private mapService: MapService
  ) {


  }

  ngOnInit(): void {

  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchArea.nativeElement.focus()
      this.searchArea.nativeElement.value = this.mapService.searchQuery
    })

  }

  public searchStations(searchQuery: string) {
    this.eventService.search(searchQuery);
  }

}
