import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchHighlight'
})
export class SearchHighlightPipe implements PipeTransform {

  transform(value: string, args: string): string {
    if (!args) { return value }
    var re = new RegExp(args, 'gi')
    return value.replace(re, "<mark>$&</mark>");
  }
}
