import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MapService } from '../../services/map.service';
import { EventService } from '../../services/event.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild('markerCoordinates') markerElement: ElementRef
  public currentMarker: any = null;
  public primaryColor: string = 'rgb(129, 19, 58)';
  public selectedColor: string = '#e40e51';
  public markers: Array<object> = [];

  constructor(
    private mapService: MapService,
    private eventService: EventService
  ) {
    this.eventService.onSelectedCard.subscribe((card: any) => {
      let cardLat = card.GEOGR_BREITE
      let cardLng = card.GEOGR_LAENGE

      let map = this.mapService.getMapInstance()
      this.mapService.addPopup(card, [cardLng, cardLat])

      if (card.detailsExpanded) {
        map.flyTo({
          center: [
            cardLng,
            cardLat
          ],
          zoom: 15,
          essential: true
        })
      }
    })
  }

  ngOnInit(): void {
    console.log('loading')
    this.mapService.buildMap();
  }
}
