<div #sidebar class="sidebar">
  <div class="resize-handle"
    [class.resizing]="resizing"
    (mousedown)="resizeStart()">
  </div>

  <div class="resize-indicator"></div>
  <div [id]="'card' + pinData.GEOGR_BREITE + pinData.GEOGR_LAENGE" [class.isActive]="pinData.GEOGR_BREITE + pinData.GEOGR_LAENGE === currentCard" (click)="selectCardPin(pinData)" *ngFor="let pinData of pinsData; trackBy: identify" class="single-item">
    <nz-card>
      <div class="card-details">
        <p (click)="pinData['detailsExpanded'] = !pinData['detailsExpanded']" class="no-margin card-header">
          <i *ngIf="!pinData['detailsExpanded']" nz-icon nzType="caret-right" nzTheme="outline"></i>
          <i *ngIf="pinData['detailsExpanded']" nz-icon nzType="caret-down" nzTheme="outline"></i>
          <b>{{pinData.KUERZEL}}</b> - {{pinData.BEZEICHNUNG}}
        </p>

        <div *ngIf="pinData['detailsExpanded']" class="details-expanded">
          {{parsePinDetails(pinData)}}
        </div>
      </div>
    </nz-card>
  </div>
  <div *ngIf="pinsData.length === 0" class="no-pins">
    <nz-empty></nz-empty>
  </div>
</div>